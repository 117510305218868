import React from 'react';
import { CircleLoader, LoaderContainer, LoaderLogoIcon } from './style';

const LoaderWithIcon = ({ style }) => {
  return (
    <LoaderContainer style={style}>
      <CircleLoader />
      <LoaderLogoIcon />
    </LoaderContainer>
  );
};

export default LoaderWithIcon;