import styled from 'styled-components';
import { LogoIconStyled } from '../../icons/Icons';

export const CircleLoader = styled.div`
    position: relative;
    display: flex;
    border: 0.125rem solid #E55C61;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border-bottom-color: transparent;
    border-right-color: transparent;
    background-color: transparent;
    margin: 0;
    padding: 0;
    animation: rotate 1.5s linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
`;

export const LoaderLogoIcon = styled(LogoIconStyled)`
  position: absolute;
  top: 1rem;
  left: 0.625rem;
  opacity: 0.9;
`;

export const LoaderBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: #FAFBFF;
  z-index: 999;
  opacity: 0.6;
`;

export const LoaderWithBackgroundContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SmallLoaderStyled = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 3px solid #E55C61;
    animation: prixClipFix 2s linear infinite ;
  }

  @keyframes rotate {
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }
`;