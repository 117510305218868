import React from 'react';
import LoaderWithIcon from './LoaderWithIcon';
import * as S from './style';

const LoaderWithBackground = () => (
  <>
    <S.LoaderBackground />
    <S.LoaderWithBackgroundContent>
      <LoaderWithIcon />
    </S.LoaderWithBackgroundContent>
  </>
);

export default LoaderWithBackground;